import React, { createContext, useState } from "react";
import { IntlProvider } from "react-intl";
import enUsMsg from "../lang/en-US.json";
import koMsg from "../lang/ko.json";

/**
 * 언어 정보
 */
export const locales = [
  { value: "ko", name: "한국어" },
  { value: "en-US", name: "English" },
  { value: "id", name: "Bahasa Indonesia" },
  { value: "ar", name: "العربية" },
  { value: "vi", name: "Tiếng Việt" },
  { value: "th", name: "ภาษาไทย" },
  { value: "zh-CN", name: "简体中文" },
  { value: "zh-TW", name: "繁體中文" },
  { value: "ja", name: "日本語" },
  { value: "tr", name: "Türkçe" },
  { value: "ms", name: "Bahasa Malaysia"},
  { value: "fr", name: "Français" },
  { value: "de", name: "Deutsch" },
  { value: "pt", name: "Português" },
  { value: "ru", name: "Русский" },
  { value: "es", name: "Español" },
  { value: "tl", name: "Tagalog" },
  { value: "mn", name: "Монгол хэл" },
  { value: "kk", name: "Қазақша" },
  { value: "uz", name: "Oʻzbekcha" },
];

export const LocaleContext = createContext(null);

function LocaleContextProvider({ children }) {
  /**
   * 현재 언어
   */
  const [locale, setLocale] = useState(
    localStorage.getItem("locale") ?? "en-US",
  );

  /**
   * 메시지팩
   */
  const messages = {
    "en-US": enUsMsg,
    id: enUsMsg,
    ar: enUsMsg,
    vi: enUsMsg,
    th: enUsMsg,
    "zh-CN": enUsMsg,
    "zh-TW": enUsMsg,
    ja: enUsMsg,
    ms: enUsMsg,
    tr: enUsMsg,
    fr: enUsMsg,
    de: enUsMsg,
    pt: enUsMsg,
    ru: enUsMsg,
    es: enUsMsg,
    tl: enUsMsg,
    ko: koMsg,
    mn: enUsMsg,
    kk: enUsMsg,
    uz: enUsMsg,
  }[locale];

  /**
   * 언어 변경
   * @param locale 변경 대상 언어
   */
  const handleLocale = (locale) => {
    localStorage.setItem("locale", locale);
    setLocale(locale);
  };

  return (
    <LocaleContext.Provider
      value={{
        locale,
        setLocale: handleLocale,
      }}
    >
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}

export default LocaleContextProvider;
