import { io } from "socket.io-client";

const Socket = io("/", {
  path: "/sock/socket.io",
  cors: {
    origin: "*",
  },

  transports: ["websocket"],
  upgrade: false,
  autoConnect: true,
  reconnection: true, // 재시도 활성화
  reconnectionAttempts: 5, // 최대 재시도 횟수
  reconnectionDelay: 10000, // 재시도 간격 (밀리초)
  forceNew: false,
  pingInterval: 20000, // 20초마다 클라이언트에서 서버로 ping을 보냄
  pingTimeout: 18000000, // 서버가 pong을 보내지 않을 경우 5초 후에 연결을 종료함
});

// 연결 실패 시
Socket.on("connect_error", (e) => {
  console.log(`socket connect_error: ${e}`);
});

// 연결이 성공적으로 재시도될 때 이벤트
Socket.on("reconnect", (attemptNumber) => {
  console.log("Reconnected after %d attempts", attemptNumber);
});

// 최종 재시도가 실패한 후 이벤트
Socket.on("reconnect_failed", (e) => {
  console.log("Reconnection failed");
});

const interval = setInterval(() => {
  Socket.emit("ping");
}, 20000); // 20초

Socket.on("disconnect", () => {
  clearInterval(interval);
});

export default Socket;
