import { Box } from "@mui/material";
import { styled } from "styled-components";
import Messages from "../../../components/Messages";
import ChatInput from "../../../components/ChatInput";
import { useChat } from "../../../hooks/useChat";
import Header from "../../../components/Header";
import Navigation from "../../../components/Navigation";
import MessageModal from "./MessageModal";
import QrModal from "./QrModal";
import useLogout from "../../../hooks/useLogout";

const Chat = () => {
  const {
    ref,
    company,
    message,
    messages,
    fdAccessUrl,
      fdUserName,
    isQrModalOpen,
    isMessageModalOpen,
    handleBlurMessage,
    handleFocusMessage,
    handleQrModalOpen,
    handleQrModalClose,
    handleChangeMessage,
    handleSubmitMessage,
    handleClickCopyMessage,
    handleMessageModalOpen,
    handleMessageModalClose,
    handleClickQrCodeDownload,
    handleClickChatHistoryDownload,
    handleClickShowOriginalMessage,
  } = useChat();
  const { handleClickLogout } = useLogout();

  return (
    <Container ref={ref}>
      {/* 헤더 */}
      <Header />

      <ContentWrapper>
        <Content>
          {/* 메시지 내용 */}
          <Messages
            messages={messages}
            handleMessageModalOpen={handleMessageModalOpen}
          />

          {/* 메시지 입력 */}
          <ChatInput
            message={message}
            handleChangeMessage={handleChangeMessage}
            handleSubmitMessage={handleSubmitMessage}
            handleFocusMessage={handleFocusMessage}
            handleBlurMessage={handleBlurMessage}
          />
        </Content>

        {/* 메뉴 */}
        <Navigation
          handleClickLogout={handleClickLogout}
          handleQrModalOpen={handleQrModalOpen}
          handleClickChatHistoryDownload={handleClickChatHistoryDownload}
        />
      </ContentWrapper>

      {/* 메시지 모달 */}
      <MessageModal
        isMessageModalOpen={isMessageModalOpen}
        handleClickCopyMessage={handleClickCopyMessage}
        handleMessageModalClose={handleMessageModalClose}
        handleClickShowOriginalMessage={handleClickShowOriginalMessage}
      />

      {/* QR 모달 */}
      <QrModal
        company={company}
        fdAccessUrl={fdAccessUrl}
        fdUserName={fdUserName}
        isOpenModal={isQrModalOpen}
        handleModalClose={handleQrModalClose}
        handleClickQrCodeDownload={handleClickQrCodeDownload}
      />
    </Container>
  );
};

export default Chat;

const Container = styled.div`
  background-color: #f0f4f8;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

const Content = styled(Box)`
  width: 100%;
`;
