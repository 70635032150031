import { Box, Button, ButtonGroup, Modal } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { styled } from "styled-components";

const MessageModal = ({
  isMessageModalOpen,
  handleClickCopyMessage,
  handleMessageModalClose,
  handleClickShowOriginalMessage,
}) => {
  /**
   * 메시지 모달 닫기
   * @param event event 객체
   */
  const onMessageModalClose = (event) => {
    event.preventDefault();
    handleMessageModalClose();
  };

  return (
    <Modal
      open={isMessageModalOpen}
      onClose={handleMessageModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContent>
        <ButtonGroup
          size="small"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 6,
          }}
        >
          {/* 원문 보기 */}
          <ModalButton type="button" onClick={handleClickShowOriginalMessage}>
            <FormattedMessage id="message0019" />
          </ModalButton>

          {/* 오번역 신고하기 */}
          {/*<ModalButton
              type="button"
              onClick={handleReportWrongTranslationMessage}
            >
              <FormattedMessage id="message0018" />
            </ModalButton>*/}

          {/* 메시지 삭제하기 */}
          {/*<ModalButton
              type="button"
            >
              <FormattedMessage id="message0017" />
            </ModalButton>*/}

          {/* 메시지 내용 복사하기 */}
          <ModalButton type="button" onClick={handleClickCopyMessage}>
            <FormattedMessage id="message0016" />
          </ModalButton>

          {/* 모달 닫기 */}
          <Button
            type="button"
            variant="contained"
            color="error"
            onClick={onMessageModalClose}
          >
            <FormattedMessage id="message0020" />
          </Button>
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
};

export default MessageModal;

const ModalContent = styled(Box).attrs(() => ({
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
}))`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalButton = styled(Button).attrs(() => ({
  sx: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "primary.main",
  },
}))``;
