import { useEffect, useRef } from "react";
import { styled } from "styled-components";
import Message from "../Message";
import LineMessage from "../Message/LineMessage";

const Messages = ({ handleMessageModalOpen, messages = [] }) => {
  /**
   * scroll box ref
   */
  const scrollBoxRef = useRef(null);

  // 새로운 메시지 수신, 새로운 메시지 송신한 경우 하단으로 이동 처리
  useEffect(() => {
    if (scrollBoxRef && scrollBoxRef.current) {
      scrollBoxRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [messages]);

  return (
    <ScrollBox id="scrollBox">
      <MessageBox id="messageBox">
        {messages.map((message, index) => {
          // 일반 메시지
          if (message.type === "msg") {
            return (
              <Message
                key={index}
                message={message}
                handleMessageModalOpen={handleMessageModalOpen}
              />
            );
          }

          // 채팅방 참가 메시지
          if (message.type === "join") {
            return <LineMessage key={index} message={message} />;
          }

          // 호스트 강퇴 처리 메시지
          if (message.type === "bring-out") {
            return <LineMessage key={index} message={message} />;
          }

          // 상대방 채팅방 나가기 메시지
          if (message.type === "left") {
            return <LineMessage key={index} message={message} />;
          }

          // 그 외
          return <div key={index}></div>;
        })}
        <div ref={scrollBoxRef} style={{ visibility: "hidden" }} />
      </MessageBox>
    </ScrollBox>
  );
};

export default Messages;

const ScrollBox = styled.div`
  height: calc(100vh - 128px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const MessageBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  background-color: #f0f4f8;
  padding: 10px;
  flex-direction: column;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
