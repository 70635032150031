import instance from "./axiosInstance";

/**
 * 메시지 오번역 신고
 * @param payload
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const reportWrongTranslationMessage = (payload) => {
  return instance.post("/report", payload);
};
