import { FormattedMessage } from "react-intl";
import { styled } from "styled-components";
import { Button, MenuItem, Select } from "@mui/material";
import { useMain } from "../../../hooks/useMain";

import LogoImg from "../../../resources/imgs/ace biz text.png";
import CopyRightImg from "../../../resources/imgs/copyrignt.svg";

const Main = () => {
  const { onChangeLocale, locale, locales, serial, handleClickGuestLogin } =
    useMain();

  return (
    <Container>
      <LoginContainer>
        <LoginBox>
          {/* 로고 */}
          <Logo />

          {/* 언어 변경 */}
          <Select size="small" value={locale} onChange={onChangeLocale}>
            {locales.map(({ name, value }) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>

          {/* serial */}
          <input type="hidden" value={serial} />

          {/* 로그인 버튼 */}
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleClickGuestLogin}
          >
            <FormattedMessage id="message0014" />
          </Button>

          {/* 설명 */}
          <Description>
            This service is for assistive communication. For professional
            advice(legal issues, medical counseling, etc), please contact
            professional interpreter
          </Description>
        </LoginBox>
      </LoginContainer>

      {/* 카피라이트 */}
      <CopyRight />
    </Container>
  );
};

export default Main;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const LoginContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
  width: 100%;
  max-width: 560px;
`;

const LoginBox = styled.div`
  background-color: #fff;

  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding: 36px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Logo = styled.div`
  background-image: url("${LogoImg}");
  height: 57px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const Description = styled.div`
  font-size: 12px;
  color: #7c7b7e;
  font-weight: 500;
`;

const CopyRight = styled.div`
  width: calc(100% - 32px);
  height: 24px;
  background-image: url("${CopyRightImg}");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 16px;
`;
