import { Button } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";

const HeaderLeft = ({
  isOpenSideMenu,
  handleClickLogout,
  handleClickOpenSideBar,
  handleClickCloseSideBar,
}) => {
  // 게스트인 경우, 메뉴 기능 대신 로그아웃 기능만 사용 가능
  if (process.env.REACT_APP_PROFILE === "GUEST") {
    return (
      <Button onClick={handleClickLogout}>
        <ExitToAppIcon sx={{ color: "#627d98" }} />
      </Button>
    );
  }

  // 호스트인 경우 / 메뉴가 열린 경우, 닫기 처리
  if (isOpenSideMenu) {
    return (
      <Button onClick={handleClickCloseSideBar}>
        <CloseIcon sx={{ color: "#627d98" }} />
      </Button>
    );
  }

  // 호스트인 경우 / 메뉴가 열린 경우, 열기 처리
  return (
    <Button onClick={handleClickOpenSideBar}>
      <SettingsIcon sx={{ color: "#627d98" }} />
    </Button>
  );
};

export default HeaderLeft;
