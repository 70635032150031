import { Route, Routes } from "react-router-dom";

import HostChat from "./host/chat";
import HostMain from "./host/main";

import GuestChat from "./guest/chat";
import GuestMain from "./guest/main";

const Pages = () => {
  console.log(process.env.REACT_APP_PROFILE);

  // 호스트 페이지
  if (process.env.REACT_APP_PROFILE === "HOST") {
    return (
      <Routes>
        <Route path="/:companyCode" element={<HostMain />} />
        <Route path="/:companyCode/chat" element={<HostChat />} />
        <Route path="/*" element={<h1>404 Not Found</h1>} />
      </Routes>
    );
  }

  // 게스트 페이지
  return (
    <Routes>
      <Route path="/:companyCode" element={<GuestMain />} />
      <Route path="/:companyCode/chat" element={<GuestChat />} />
      <Route path="/*" element={<h1>404 Not Found</h1>} />
    </Routes>
  );
};

export default Pages;
