import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { v4 as uuidv4 } from "uuid";

const initialValues = {
  messages: [],
};

export const useMessageStore = create(
  persist(
    (set) => ({
      values: initialValues,

      /**
       * 메시지 목록 초기화
       */
      resetMessages: () =>
        set((state) => ({
          ...state,
          values: {
            ...state.values,
            messages: [],
          },
        })),

      /**
       * 메시지 원복 보기
       * @param id 메시지 ID
       */
      showOriginalMessage: (id) =>
        set((state) => ({
          ...state,
          values: {
            ...state.values,
            messages: state.values.messages.map((message) =>
              message.id === id ? { ...message, isShowOrigin: true } : message,
            ),
          },
        })),

      /**
       * 메시지 추가
       * @param message 메시지 추가
       */
      addMessage: (message) =>
        set((state) => ({
          ...state,
          values: {
            ...state.values,
            messages: message
              ? [
                  ...state.values.messages,
                  {
                    ...message,
                    id: uuidv4(),
                    isShowOrigin: false,
                    createDate: new Date(),
                  },
                ]
              : state.values.messages,
          },
        })),
    }),
    {
      name: "message-store",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
