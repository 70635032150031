import dayjs from "dayjs";
import { styled } from "styled-components";

const LineMessage = ({ message }) => {
  /**
   * 메시지 생성 시간 포맷
   */
  let datetime = dayjs(message?.createDate).format("YYYY-MM-DD HH:mm");

  return (
    <LineMessageContainer>
      {datetime ? <span>{datetime}</span> : null}
      {message?.message ? <span>{message?.message}</span> : null}
    </LineMessageContainer>
  );
};

export default LineMessage;

const LineMessageContainer = styled.div`
  width: 100%;
  min-height: 40px;
  height: auto;
  font-size: 11px;
  text-align: center;
  color: #797979;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
`;
