import { useContext } from "react";
import { ChatContext } from "../context/ChatContext";
import { useCommonStore } from "../store/useCommonStore";
import { useNavigate } from "react-router-dom";
import { useMessageStore } from "../store/useMessageStore";

const useLogout = () => {
  const navigate = useNavigate();
  const {
    onCount,
    onDisconnect,
    handleClickCloseSideBar,
    clearCheckAvailableInterval,
  } = useContext(ChatContext);

  const {
    values: { company, serial },
    resetCommonStore,
  } = useCommonStore();

  const { resetMessages } = useMessageStore();

  /**
   * 로그아웃 처리 click 이벤트
   * @param event event 객체
   */
  const handleClickLogout = (event) => {
    event.preventDefault();

    // 무슨 역활인지 모름
    onCount(serial);

    // socket 연결 여부 확인 및 재연결 Interval 해제
    clearCheckAvailableInterval();

    // socket 연결 해제
    onDisconnect();

    // 메뉴 닫기
    handleClickCloseSideBar();

    // 로그인 정보 등 초기화
    resetCommonStore();

    // 메시지 목록 초기화
    resetMessages();

    // 메인으로 이동
    navigate(`/${company}/?qr=${serial}`);
  };

  return { handleClickLogout };
};

export default useLogout;
