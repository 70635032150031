import { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import html2canvas from "html2canvas";
import * as saveAs from "file-saver";
import { ChatContext } from "../context/ChatContext";
import { LocaleContext } from "../context/LocaleContext";
import { useCommonStore } from "../store/useCommonStore";
import { useIntl } from "react-intl";
import { useMessageStore } from "../store/useMessageStore";
import { reportWrongTranslationMessage } from "../apis/message.api";

export const useChat = () => {
  /**
   * react-intl
   */
  const intl = useIntl();

  /**
   * ref
   */
  const ref = useRef(null);

  /**
   * 입력한 메시지
   */
  const [message, setMessage] = useState("");

  /**
   * 선택한 메시지
   */
  const [selectedMessage, setSelectedMessage] = useState(null);

  /**
   * 메시지 모달 오픈 여부
   */
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  /**
   * QR 모달 오픈 여부
   */
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);

  const { locale } = useContext(LocaleContext);
  const {
    onSendMessage,
    checkAvailable,
    setIsInputFocus,
    checkAvailableInterval,
    handleClickCloseSideBar,
  } = useContext(ChatContext);

  const {
    values: { company, fdSrCode, fdUserName, fdAccessUrl, opLang },
  } = useCommonStore();

  const {
    values: { messages },
    resetMessages,
    showOriginalMessage,
  } = useMessageStore();

  /**
   * 메시지 오번역 신고
   * @param event 이벤트 객체
   * @returns {Promise<void>}
   */
  const handleReportWrongTranslationMessage = async (event) => {
    event.preventDefault();
    try {
      const message = messages.find(
        (message) => message.id === selectedMessage,
      );
      const { data } = await reportWrongTranslationMessage({
        lang: message.org_lang,
        lang_tr: message.lang_tr,
        text_org: message.message,
        text_tr: message.tr,
        text_eng: message.en,
      });
      if (data?.result) {
        toast(data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsMessageModalOpen(false);
    }
  };

  /**
   * 메시지 복사
   * @param event event 객체
   */
  const handleClickCopyMessage = (event) => {
    event.preventDefault();

    const { message, tr, nickname } = (messages || []).find(
      (message) => message.id === selectedMessage,
    );

    if (typeof navigator.clipboard === "undefined") {
      const textArea = document.createElement("textarea");
      textArea.value = nickname === "Host" ? tr : message;
      textArea.style.display = "none";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
        alert("Copied.");
      } catch (error) {
        console.log(error);
      }

      document.body.removeChild(textArea);
      return;
    }
    navigator.clipboard.writeText(nickname === "Host" ? tr : message).then(
      () => {
        alert("Copied.");
      },
      (error) => {
        console.log(error);
      },
    );
  };

  /**
   * 메시지 원복 보기
   * @param event event 객체
   */
  const handleClickShowOriginalMessage = (event) => {
    event.preventDefault();
    showOriginalMessage(selectedMessage);
    setIsMessageModalOpen(false);
  };

  /**
   * 메시지 모달 열기
   * @param id 메시지 ID
   */
  const handleMessageModalOpen = (id) => {
    setSelectedMessage(id);
    setIsMessageModalOpen(true);
  };

  /**
   * 메시지 모달 닫기
   */
  const handleMessageModalClose = () => {
    setSelectedMessage(null);
    setIsMessageModalOpen(false);
  };

  /**
   * 메시지 입력 포커스 이벤트 처리
   * @param event event 객체
   */
  const handleFocusMessage = (event) => {
    event.preventDefault();
    setIsInputFocus(true);
    handleClickCloseSideBar(event);
  };

  /**
   * 메시지 입력 포커스 해제 이벤트 처리
   * @param event event 객체
   */
  const handleBlurMessage = (event) => {
    event.preventDefault();
    setIsInputFocus(true);
  };

  /**
   * 메시지 입력 처리 이벤트
   * @param event event 객체
   */
  const handleChangeMessage = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setMessage(value);
  };

  /**
   * 메시지 전송 처리 이벤트
   * @param event event 객체
   */
  const handleSubmitMessage = (event) => {
    event.preventDefault();

    try {
      if (!message) return;

      onSendMessage({
        opLang: opLang || locale,
        lang: locale,
        message,
        fdSrCode,
      });

      setMessage("");
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 채팅 이력 이미지로 다운로드
   * @param event event 객체
   * @returns {Promise<void>}
   */
  const handleClickChatHistoryDownload = async (event) => {
    event.preventDefault();
    try {
      const messageBox = document.getElementById("messageBox");
      const canvas = await html2canvas(messageBox, { scale: 2 });
      canvas.toBlob((blob) => {
        if (blob !== null) {
          saveAs(blob, "Chat-" + new Date() + ".png");
          toast(intl.formatMessage({ id: "message0009" }));
        }
      });
    } catch (error) {
      console.error(error);
      toast("채팅 내역 다운로드 중 에러가 발생하였습니다.");
    }
  };

  /**
   * 게스트 사이트 접속 QR Code 다운로드
   * @param event event 객체
   * @returns {Promise<void>}
   */
  const handleClickQrCodeDownload = async (event) => {
    event.preventDefault();
    try {
      const qrBox = document.getElementById("qrBox");
      const canvas = await html2canvas(qrBox, { scale: 2 });
      canvas.toBlob((blob) => {
        if (blob !== null) {
          saveAs(blob, "QR-code.png");
          toast(intl.formatMessage({ id: "message0024" }));
        }
      });
    } catch (error) {
      console.error("Error converting div to image:", error);
    }
  };

  /**
   * QR 모달 열기
   * @param event event 객체
   */
  const handleQrModalOpen = (event) => {
    event.preventDefault();
    if (!fdAccessUrl.trim() || !company.trim()) {
      toast("QR코드 호출 실패. 서비스를 종료 후 다시 실행해주세요.");
      return;
    }
    setIsQrModalOpen(true);
  };

  /**
   * QR 모달 닫기
   * @param event event 객체
   */
  const handleQrModalClose = (event) => {
    event.preventDefault();
    setIsQrModalOpen(false);
  };

  useEffect(() => {
    // Socket 및 채팅방 연결
    checkAvailable();
    // Socket 연결이 해제된 경우, 연결 처리하는 Interval
    checkAvailableInterval();
    return () => {
      // 페이지를 벗어난 경우 메시지 목록 초기화
      resetMessages();
    };
  }, []);

  // 제대로 동작하지 않음
  /*useEffect(() => {
    // 아이폰 뷰포트 대응
    const handleVisualViewPortResize = () => {
      if (ref && ref.current) {
        const currentVisualViewport = Number(window.visualViewport?.height);
        ref.current.style.height = `${currentVisualViewport + 35}px`;
        window.scrollTo(0, 40);
      }
    };
    if (window.visualViewport && /iPhone/i.test(navigator.userAgent)) {
      window.visualViewport.onresize = handleVisualViewPortResize;
    }
  }, []);*/

  return {
    ref,
    company,
    message,
    messages,
    fdAccessUrl,
    fdUserName,
    isQrModalOpen,
    isMessageModalOpen,
    handleBlurMessage,
    handleFocusMessage,
    handleChangeMessage,
    handleSubmitMessage,
    handleClickQrCodeDownload,
    handleClickChatHistoryDownload,
    handleQrModalOpen,
    handleQrModalClose,
    handleMessageModalOpen,
    handleMessageModalClose,
    handleClickCopyMessage,
    handleClickShowOriginalMessage,
    handleReportWrongTranslationMessage,
  };
};
