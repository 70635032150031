import instance from "./axiosInstance";

/**
 * 호스트 로그인
 * @param payload
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const login = (payload) => {
  return instance.post("/host/login", payload);
};

/**
 * 게스트 로그인
 * @param payload
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const loginGuest = (payload) => {
  return instance.post("/guest/login", payload);
};

/**
 * 방정보 얻기
 * @param payload
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getRoom = (payload) => {
  return instance.get(`/room/host?qrcode=${payload}`);
};

/**
 * 게스트 기접속 여부 확인
 * @param payload
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getCount = (payload) => {
  return instance.get(`/count?qrcode=${payload}`);
};
