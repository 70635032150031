import { useContext, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import toast from "react-hot-toast";
import { ChatContext } from "../context/ChatContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LocaleContext, locales } from "../context/LocaleContext";
import { useCommonStore } from "../store/useCommonStore";
import { getCount, getRoom, login, loginGuest } from "../apis/common.api";
import { useMessageStore } from "../store/useMessageStore";

export const useMain = () => {
  /**
   * react-intl
   */
  const intl = useIntl();

  /**
   * react-router-dom 페이지 이동을 위한 hook
   */
  const navigate = useNavigate();

  /**
   * query string 목록 가져오기
   */
  const [searchParams] = useSearchParams();

  /**
   * company 코드
   */
  const { companyCode } = useParams();

  const { locale, setLocale } = useContext(LocaleContext);

  const { onCount, onReconnect } = useContext(ChatContext);

  const {
    values: { serial, company },
    setCompany,
      setFdUserName,
    setSerial,
    setFdAccessUrl,
    setFdSrCode,
    setOpLang,
    setParticipantCount,
  } = useCommonStore();

  const { resetMessages } = useMessageStore();

  /**
   * 더미 회사 코드
   */
  const dummyCompany = useMemo(() => {
    const [company = ""] = serial.split("-");
    return company;
  }, [serial]);

  /**
   * 호스트 로그인
   * @param event event 객체
   * @returns {Promise<void>}
   */
  const handleClickLogin = async (event) => {
    event.preventDefault();
    try {
      resetMessages();
      onReconnect();

      const {
        data: { data, result, message },
      } = await login({
        username: "admin",
        qr_code: serial,
      });
      const [{ fd_sr_code, fd_access_url, fd_username }] = data;

      if (!result) {
        toast(message);
        return;
      }
      let query = fd_sr_code;

      if (!fd_access_url) {
        toast("QR코드 호출 실패. 서비스를 종료 후 다시 실행해주세요.");
        return;
      }

      setFdAccessUrl(fd_access_url);
      const { data: roomData } = await getRoom(query);

      if (roomData.result) {
        toast(intl.formatMessage({ id: "message0011" }));
      } else {
        onCount(serial);
        setFdSrCode(fd_sr_code);
        setParticipantCount(1);
        setFdUserName(fd_username);
        navigate(`/${company}/chat`);
      }
    } catch (error) {
      console.log(error);
      toast("QR코드 호출 실패. 서비스를 종료 후 다시 실행해주세요.");
    }
  };

  /**
   * 게스트 로그인
   * @param event event 객체
   * @returns {Promise<void>}
   */
  const handleClickGuestLogin = async (event) => {
    event.preventDefault();
    try {
      resetMessages();
      onReconnect();

      if (!serial) {
        toast("Please scan the QR code to access.");
        return;
      }

      const {
        data: { data, result, message },
      } = await loginGuest({
        qr_code: serial,
      });

      if (result === false) {
        toast(message);
        return;
      }

      const [{ fd_sr_code }] = data;

      const {
        data: { data: countData },
        result: countResult,
      } = await getCount(fd_sr_code);
      const [{ count }] = countData;

      if (countResult === false) {
        toast("Sorry not yet prepared.");
        return;
      }

      if (count > 1) {
        toast("Participants limit exceeded.\nPlease access later.");
        return;
      }

      if (count === 0) {
        toast(
          "The host has not entered yet. Please wait until the host enters.",
        );
        return;
      }

      const {
        data: { data: roomData },
      } = await getRoom(serial);
      const [{ fd_lang }] = roomData;

      setOpLang(fd_lang);
      setFdSrCode(fd_sr_code);
      setParticipantCount(2);

      navigate(`/${company}/chat`);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 언어 변경
   * @param event event 객체
   */
  const onChangeLocale = (event) => {
    event.preventDefault();
    const { value = "ko" } = event.target;
    setLocale(value);
  };

  // query-string으로부터 serial 정보 얻기
  useEffect(() => {
    setSerial(searchParams.get("qr"));
  }, [searchParams]);

  // url로부터 회사 코드 정보 얻기
  useEffect(() => {
    setCompany(companyCode);
  }, [companyCode]);

  return {
    onChangeLocale,
    locale,
    locales,
    company,
    dummyCompany,
    serial,
    handleClickLogin,
    handleClickGuestLogin,
  };
};
