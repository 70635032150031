import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const initialValues = {
  company: "",
  serial: "",
  fdSrCode: "",
  fdAccessUrl: "",
    fdUserName: "",
  opLang: "ko",
  participantCount: 0,
};

export const useCommonStore = create(
  persist(
    (set) => ({
      values: initialValues,

      /**
       * 회사코드 변경
       * @param company 회사코드
       */
      setCompany: (company) =>
        set((state) => ({
          ...state,
          values: {
            ...state.values,
            company,
          },
        })),

        /**
         * 사용자 이름 변경
         * @param fdUserName 사용자 이름
         */
        setFdUserName: (fdUserName) =>
            set((state) => ({
                ...state,
                values: {
                    ...state.values,
                    fdUserName,
                },
            })),

      /**
       * 시리얼 코드 변경
       * @param serial 시리얼 코드
       */
      setSerial: (serial) =>
        set((state) => ({
          ...state,
          values: {
            ...state.values,
            serial,
          },
        })),

      /**
       *
       * @param fdSrCode
       */
      setFdSrCode: (fdSrCode) =>
        set((state) => ({
          ...state,
          values: {
            ...state.values,
            fdSrCode,
          },
        })),

      /**
       * 게스트 URL 변경
       * @param fdAccessUrl 게스트 URL
       */
      setFdAccessUrl: (fdAccessUrl) =>
        set((state) => ({
          ...state,
          values: {
            ...state.values,
            fdAccessUrl,
          },
        })),

      /**
       * 상대방 언어 변경
       * @param opLang 상대방 언어
       */
      setOpLang: (opLang) =>
        set((state) => ({
          ...state,
          values: {
            ...state.values,
            opLang,
          },
        })),

      /**
       * 참가자 수 변경
       * @param participantCount 참가자 수
       */
      setParticipantCount: (participantCount) =>
        set((state) => ({
          ...state,
          values: {
            ...state.values,
            participantCount,
          },
        })),

      /**
       * 공통 Store 초기화
       */
      resetCommonStore: () =>
        set((state) => ({
          ...state,
          values: initialValues,
        })),
    }),
    {
      name: "common-store",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
