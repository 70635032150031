import React from "react";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import LocaleContextProvider from "./context/LocaleContext";
import ChatContextProvider from "./context/ChatContext";
import { createTheme, ThemeProvider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import Pages from "./pages";

import "./App.css";

const Contents = () => {
  return (
    <ChatContextProvider>
      <CssBaseline />
      <Pages />
    </ChatContextProvider>
  );
};

function App() {
  const theme = createTheme({
    palette: {
      mode: "light",
      // 색상 반전 비활성화
      background: {
        default: "#ffffff", // 배경색을 원래 색으로 변경
      },
      text: {
        primary: "#000000", // 텍스트 색상을 원래 색으로 변경
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <LocaleContextProvider>
          <Contents />
        </LocaleContextProvider>
      </BrowserRouter>
      <Toaster />
    </ThemeProvider>
  );
}

export default App;
