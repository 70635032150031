import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import QRCode from "qrcode.react";
import { styled } from "styled-components";
import { Button } from "@mui/material";

const QrModal = ({
                   company,
                   fdAccessUrl,
                   fdUserName,
                   isOpenModal,
                   handleModalClose,
                   handleClickQrCodeDownload,
                 }) => {
  return (
    <Dialog
      onClose={handleModalClose}
      aria-labelledby="qr-download-modal"
      open={isOpenModal}
    >
      <ModalContent>
        {/* 모달 닫기 */}
        <CloseButton onClick={handleModalClose} />

        <QrCodeContainer>
          <h2>QR Code</h2>

          {/* QR Code */}
          <QrBox id="qrBox">
            <QRCode value={fdAccessUrl} size={150} />
            <QrText>{fdUserName}</QrText>
          </QrBox>

          {/* QR Code 다운로드 */}
          <Button variant="contained" onClick={handleClickQrCodeDownload}>
            Download
          </Button>
        </QrCodeContainer>
      </ModalContent>
    </Dialog>
  );
};

export default QrModal;

const QrCodeContainer = styled.div`
  text-align: center;
  font-weight: bold;

  padding: 20px;

  h2 {
    font-size: 30px;
  }
`;

const QrBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  width: 190px;
`;

const QrText = styled.div`
  width: 150px;
  overflow-x: hidden;
  overflow-wrap: break-word;
`;

const ModalContent = styled.div`
  background-color: #ffffff;
`;

const CloseButton = styled(IconButton).attrs(() => ({
  "aria-label": "close",
  sx: {
    position: "absolute",
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
  },
  children: <CloseIcon />,
}))``;
