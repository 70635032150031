import { useContext } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "styled-components";
import { ChatContext } from "../../context/ChatContext";
import useLogout from "../../hooks/useLogout";
import HeaderLeft from "./HeaderLeft";

import LogoImg from "../../resources/imgs/ace biz text.png";

const Header = () => {
  const { isOpenSideMenu, handleClickOpenSideBar, handleClickCloseSideBar } =
    useContext(ChatContext);
  const { handleClickLogout } = useLogout();

  return (
    <Container>
      <Toolbar
        sx={{
          boxShadow: "none",
          textAlign: "right",
          backgroundColor: "#fff",
          width: "100%",
          display: "flex",
          height: "64px",
          justifyContent: "space-between",
        }}
      >
        <Logo />
        <HeaderLeft
          isOpenSideMenu={isOpenSideMenu}
          handleClickLogout={handleClickLogout}
          handleClickCloseSideBar={handleClickCloseSideBar}
          handleClickOpenSideBar={handleClickOpenSideBar}
        />
      </Toolbar>
    </Container>
  );
};

export default Header;

const Container = styled(Box)`
  flex: 1;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;

const Logo = styled.h1`
  background-image: url("${LogoImg}");
  background-size: contain;
  background-repeat: no-repeat;
  width: 150px;
  height: 24px;
  margin: 0;
  padding: 0;
`;
