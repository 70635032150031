import { useRef, useState, useEffect, useMemo } from "react";
import { styled } from "styled-components";
import { v4 as uuidv4 } from "uuid";
import SendIcon from "@mui/icons-material/Send";

const sendButtonId = uuidv4();

const ChatInput = ({
  message = "",
  handleFocusMessage,
  handleBlurMessage,
  handleChangeMessage,
  handleSubmitMessage,
}) => {
  /**
   * textarea Ref
   */
  const textAreaRef = useRef(null);

  /**
   * textarea 높이
   */
  const [initialHeight, setInitialHeight] = useState(0);

  /**
   * 메시지 전송 가능 여부
   */
  const isAbleSubmit = useMemo(() => {
    return message.trim().replaceAll("\n", "").length > 0;
  }, [message]);

  /**
   * 메시지 입력 처리
   * @param event event 객체
   */
  const onChange = (event) => {
    handleChangeMessage(event);
    if (isAbleSubmit) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    } else {
      textAreaRef.current.style.height = `40px`;
    }
  };

  /**
   * PC에서 Enter + Shift 입력 시 줄바꿈, 긔 외 메시지 전송 처리 막기
   * TODO: 모바일에서 엔터 시, 줄바꿈 처리 필요
   * @param event event 객체
   */
  const onKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
    }
  };

  /**
   * PC에서 Enter + Shift 입력 시 줄바꿈, 긔 외 메시지 전송 처리
   * TODO: 모바일에서 엔터 시, 줄바꿈 처리 필요
   * @param event event 객체
   */
  const onKeyUp = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit(event); // enter 키에서 손을 뗐을 때 제출합니다.
    }
  };

  /**
   * 메시지 전송 처리
   * @param event event 객체
   */
  const handleSubmit = (event) => {
    handleSubmitMessage(event);
    textAreaRef.current.style.height = `${initialHeight}px`;
    textAreaRef.current.focus();
  };

  // 메시지 입력 시, 줄바꿈 처리에 따른 높이 변경 처리
  useEffect(() => {
    setInitialHeight(textAreaRef.current.scrollHeight);
  }, []);

  // 키보드가 열린 상태에서 메시지 입력/전송 행동 외에는 키보드 닫기 처리
  useEffect(() => {
    const handleTouchStart = (event) => {
      event.preventDefault();

      if (!event.target.classList.contains(sendButtonId)) {
        document.activeElement.blur();
      }
    };
    document.addEventListener("touchstart", handleTouchStart);
    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);

  return (
    <Container onSubmit={handleSubmit}>
      <InputContainer className={sendButtonId}>
        <Input
          type="text"
          value={message}
          maxLength={300}
          className={sendButtonId}
          ref={textAreaRef}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onFocus={handleFocusMessage}
          onBlur={handleBlurMessage}
          $isAbleSubmit={isAbleSubmit}
        />
        {isAbleSubmit ? (
          <SendButton type="submit" className={sendButtonId} />
        ) : null}
      </InputContainer>
    </Container>
  );
};

export default ChatInput;

const Container = styled.form`
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  position: sticky;
  bottom: 0;
  left: 0;
`;

const InputContainer = styled.div`
  background-color: #f0f4f8;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & * {
    box-sizing: border-box;
  }
`;

const Input = styled.textarea.attrs(() => ({
  rows: 1,
}))`
  resize: none;
  background-color: #f0f4f8;
  width: ${({ $isAbleSubmit }) =>
    $isAbleSubmit ? "calc(100% - 48px)" : "100%"};
  border: none;
  border-radius: 10px;
  height: auto;
  max-height: 300px;
  padding: ${({ $isAbleSubmit }) =>
    $isAbleSubmit ? "10px 0 10px 10px" : "10px"};

  &:focus-visible {
    outline: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const SendButton = styled.button.attrs(() => ({
  children: <SendIcon color="primary" className={sendButtonId} />,
}))`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: none;
  background-color: #f0f4f8;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
`;
