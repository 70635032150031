import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Socket from "../utils/socket";
import usePushNotification from "../hooks/usePushNotification";
import { LocaleContext } from "./LocaleContext";
import { useMessageStore } from "../store/useMessageStore";
import { useCommonStore } from "../store/useCommonStore";

export const ChatContext = createContext(null);

function ChatContextProvider({ children }) {
  const navigate = useNavigate();

  /**
   * 메뉴 오픈 여부
   */
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(false);

  /**
   * Socket 연결 체크 Interval ID
   */
  const [prevAvailableId, setPrevAvailableId] = useState(null);

  /**
   * 메시지 입력 칸 포커스 여부
   */
  const [isInputFocus, setIsInputFocus] = useState(false);

  /**
   * socket
   */
  const socket = Socket;

  const { fireNotificationWithTimeout } = usePushNotification();

  const { locale } = useContext(LocaleContext);

  const {
    values: { serial, company, fdSrCode },
    setOpLang,
    setParticipantCount,
  } = useCommonStore();

  const { addMessage } = useMessageStore();

  /**
   * 메뉴 닫기 처리
   * @param event event 객체
   */
  const handleClickCloseSideBar = (event) => {
    if (event) event.preventDefault();
    setIsOpenSideMenu(false);
  };

  /**
   * 메뉴 열기 처리
   * @param event event 객체
   */
  const handleClickOpenSideBar = (event) => {
    if (event) event.preventDefault();
    setIsOpenSideMenu(true);
  };

  /**
   * 게스트 강퇴 처리
   */
  const onDisconnectGuest = () => {
    console.log('######################### 게스트 강퇴 처리 ###############')
    socket.emit("bring-out");
  };

  /**
   * 메시지 전송
   * @param opLang 상대방 언어
   * @param lang 본인 언어
   * @param message 메시지
   * @param fdSrCode 방코드
   */
  const onSendMessage = ({ opLang, lang, message, fdSrCode }) => {
    socket.emit("message", {
      op_lang: opLang,
      lang,
      message,
      room: fdSrCode,
    });
  };

  /**
   * Socket 재연결
   */
  const onReconnect = () => {
    socket.disconnect();
    socket.connect();
  };

  /**
   * Socket 연결 해제
   */
  const onDisconnect = () => {
    socket.disconnect();
  };

  /**
   * 어떠한 역활인지 확인 필요
   * @param count
   */
  const onCount = (count) => {
    socket.emit("count", count);
  };

  /**
   * 채팅방 연결
   * @param room 방코드
   * @param lang 본인 언어
   */
  const onJoin = ({ room, lang }) => {
    if (process.env.REACT_APP_PROFILE === "HOST") {
      socket.emit("join", {
        nickname: "Host",
        room,
        serial,
        lang,
      });
    } else {
      socket.emit("join", {
        nickname: "Guest",
        room,
        serial,
        lang,
      });
    }
  };

  /**
   * Socket 및 채팅방 연결
   */
  const checkAvailable = () => {
    onReconnect();
    onJoin({
      room: fdSrCode,
      lang: locale,
    });
  };

  /**
   * Socket 연결이 해제된 경우, 연결 처리하는 Interval
   */
  const checkAvailableInterval = () => {
    // const id = setInterval(() => {
    //   if (!socket.connected) {
    //     onReconnect();
    //     onJoin({
    //       room: fdSrCode,
    //       lang: locale,
    //     });
    //   }
    // }, 1000 * 10);
    // if (prevAvailableId) clearInterval(prevAvailableId);
    // setPrevAvailableId(id);
  };

  /**
   * checkAvailableInterval 함수 Interval 해제
   */
  const clearCheckAvailableInterval = () => {
    if (prevAvailableId) setPrevAvailableId(null);
  };

  // socket event listener
  useEffect(() => {
    try {
      socket.on("notify", (socket) => {
        addMessage(socket);

        if (process.env.REACT_APP_PROFILE === "HOST") {
          // 호스트 이벤트 수신

          if (socket.type === "msg") {
            // 일반 메시지 처리

            if (socket.nickname === "Guest") {
              // 게스트로부터 일반 메시지를 수신한 경우

              if (!isInputFocus) {
                // 메시지 입력 칸에 포커스가 되지 않은 경우, 알림
                fireNotificationWithTimeout("ACE BIZ", 3000, {
                  body: socket.message,
                });
              }
            }
          } else if (socket.type === "join") {
            // 채팅방 참가 메시지

            if (socket.nickname === "Host") {
              // 호스트가 참가한 경우, 참가자 수 1로 표시
              setParticipantCount(1);
            } else {
              // 게스트가 참가한 경우, 참가자 수 2로 표시 및 상대방 언어 저장
              setParticipantCount(2);
              setOpLang(socket.lang);
            }
          } else if (socket.type === "bring-out") {
            // 호스트가 게스트 강퇴 처리한 경우, 참가자 수 1로 표시
            setParticipantCount(1);
          } else if (socket.type === "left") {
            // 상대방이 채팅방을 나간 경우, 참가자 수 1로 표시
            setParticipantCount(1);
          }
        } else {
          // 게스트 이벤트 수신

          if (socket.type === "msg") {
          } else if (socket.type === "join") {
            // 채팅방 참가 메시지

            if (socket.nickname === "Guest") {
              // 게스트가 채팅방 참가한 경우, 참가자 수 2로 표시
              setParticipantCount(2);
            }
          } else if (socket.type === "bring-out") {
            // 호스트가 게스트를 강퇴한 경우, 참가자수 1로 표시
            setParticipantCount(1);
            // 게스트는 방 나가기 처리
            setTimeout(() => {
              navigate(`/${company}/?qr=${serial}`);
            }, 5000);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }

    return () => {
      socket.off("notify");
    };
  }, [socket]);

  return (
    <ChatContext.Provider
      value={{
        isInputFocus,
        isOpenSideMenu,
        setIsInputFocus,
        onJoin,
        onCount,
        onReconnect,
        onDisconnect,
        onSendMessage,
        onDisconnectGuest,
        checkAvailable,
        checkAvailableInterval,
        clearCheckAvailableInterval,
        handleClickOpenSideBar,
        handleClickCloseSideBar,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export default ChatContextProvider;
