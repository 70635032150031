import { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import toast from "react-hot-toast";
import { styled } from "styled-components";
import { Button } from "@mui/material";

import ExitToApp from "@mui/icons-material/ExitToApp";
import PeopleIcon from "@mui/icons-material/People";
import ForumIcon from "@mui/icons-material/Forum";
import QrCodeIcon from "@mui/icons-material/QrCode";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import GetAppIcon from "@mui/icons-material/GetApp";

import { LocaleContext } from "../../context/LocaleContext";
import { ChatContext } from "../../context/ChatContext";
import { useCommonStore } from "../../store/useCommonStore";

const NavItem = ({ children, label, icon, onClick }) => {
  return (
    <>
      <NavItemContainer onClick={onClick}>
        {icon}
        <span>{label}</span>
      </NavItemContainer>
      {children}
    </>
  );
};

const Navigation = ({
  handleClickLogout,
  handleQrModalOpen,
  handleClickChatHistoryDownload,
}) => {
  /**
   * react-intl
   */
  const intl = useIntl();

  /**
   * 참가자 수 메뉴 오픈 여부
   */
  const [isParticipantSubNavOpen, setIsParticipantSubNavOpen] = useState(false);

  const { locale } = useContext(LocaleContext);
  const { isOpenSideMenu, onDisconnectGuest, handleClickCloseSideBar } =
    useContext(ChatContext);

  const {
    values: { participantCount },
  } = useCommonStore();

  /**
   * 메뉴얼 링크 얻기
   * @returns {string} 메뉴얼 링크
   */
  const getManualLink = () => {
    if (locale === "ko") return "/ACE Biz 사용자메뉴얼_202405 V5_kr.pdf";
    return "/ACE Biz Manual_20240513_V1.pdf";
  };

  /**
   * 참가자 수 메뉴 ON/OFF 처리
   * @param event event 객체
   */
  const handleToggleParticipantCount = (event) => {
    event.preventDefault();
    setIsParticipantSubNavOpen((prev) => !prev);
  };

  /**
   * Guest 강퇴 처리
   * @param event event 객체
   */
  const handleClickDisconnectGuest = (event) => {
    event.preventDefault();
    onDisconnectGuest();
    toast(intl.formatMessage({ id: "message0010" }));
  };

  // 메뉴 닫기 처리
  useEffect(() => {
    const onMenuClose = (event) => {
      event.preventDefault();
      handleClickCloseSideBar();
    };

    document
      .getElementById("scrollBox")
      ?.addEventListener("click", onMenuClose);

    return () =>
      document
        .getElementById("scrollBox")
        ?.removeEventListener("click", onMenuClose);
  }, [handleClickCloseSideBar]);

  if (!isOpenSideMenu) return null;

  return (
    <>
      <Container>
        <ContainerTop>
          <Inner>
            {/* 참가자 */}
            <NavItem
              label={intl.formatMessage({ id: "message0002" })}
              icon={
                <PeopleIcon sx={{ color: "#627D98", lineHeight: "40px" }} />
              }
              onClick={handleToggleParticipantCount}
            />
            <SubNavItemContainer $on={isParticipantSubNavOpen}>
              <PeopleIcon sx={{ color: "#627D98", lineHeight: "40px" }} />
              <FormattedMessage
                id="message0003"
                values={{
                  count: participantCount,
                }}
              />
            </SubNavItemContainer>

            {/* 채팅 내역 저장 */}
            <NavItem
              label={intl.formatMessage({ id: "message0004" })}
              icon={<ForumIcon sx={{ color: "#627D98", lineHeight: "40px" }} />}
              onClick={handleClickChatHistoryDownload}
            />

            {/* QR코드 */}
            <NavItem
              label={intl.formatMessage({ id: "message0005" })}
              icon={
                <QrCodeIcon sx={{ color: "#627D98", lineHeight: "40px" }} />
              }
              onClick={handleQrModalOpen}
            />

            {/* 게스트 강퇴 */}
            <NavItem
              label={intl.formatMessage({ id: "message0006" })}
              icon={
                <PersonOffIcon sx={{ color: "#627D98", lineHeight: "40px" }} />
              }
              onClick={handleClickDisconnectGuest}
            />

            {/* 로그아웃 */}
            <NavItem
              label={intl.formatMessage({ id: "message0007" })}
              icon={<ExitToApp sx={{ color: "#627D98", lineHeight: "40px" }} />}
              onClick={handleClickLogout}
            />
          </Inner>
        </ContainerTop>

        {/* 메뉴얼 */}
        <ManualButton href={getManualLink()} />
      </Container>
    </>
  );
};

export default Navigation;

const Container = styled.div`
  min-width: 320px;
  height: calc(100% - 64px);
  background-color: #fff;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const NavItemContainer = styled.div`
  float: left;
  width: 90%;
  height: 56px;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    color: #000000;
    padding-left: 15px;
  }
`;

const ManualButton = styled(Button).attrs(() => ({
  variant: "outlined",
  sx: {
    m: 1,
  },
  startIcon: <GetAppIcon />,
  component: "a",
  rel: "noopener noreferrer",
  target: "_blank",
  children: "Manual",
}))``;

const SubNavItemContainer = styled.div`
  width: 100%;
  height: 54px;
  padding: 20px 50px;
  cursor: pointer;
  font-size: 12px;
  background-color: #f0f4f8;
  display: ${({ $on }) => ($on ? "flex" : "none")};
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const ContainerTop = styled.div``;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
`;
