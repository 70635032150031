import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "styled-components";

import "dayjs/locale/ko";

const Message = ({ message, handleMessageModalOpen }) => {
  /**
   * 메시지 생성 시간 포맷
   */
  const createDate = dayjs(message?.createDate).format("YYYY-MM-DD HH:mm");

  /**
   * 호스트인지 게스트인지 구분
   */
  const nickname = process.env.REACT_APP_PROFILE === "HOST" ? "Host" : "Guest";

  /**
   * 메시지 모달 열기
   * @param event event 객체
   */
  const handleClickMessageMore = (event) => {
    event.preventDefault();
    handleMessageModalOpen(message.id);
  };

  // 상대방 메시지
  if (message?.nickname !== nickname || !message?.nickname?.trim()) {
    return (
      <MessageContainer $isMe={false}>
        <MessageInner className="message-other">
          {nickname === "Host" ? (
            <div className="en-message">{message.en}</div>
          ) : null}
          <div
            className="translated-message"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{message.tr}</span>
          </div>
          <div className={`org-message ${message.isShowOrigin ? "" : "none"}`}>
            {message.message}
          </div>
        </MessageInner>
        <div className="tool">
          <IconButton color="string" onClick={handleClickMessageMore}>
            <MoreVertIcon />
          </IconButton>
          <span>{createDate}</span>
        </div>
      </MessageContainer>
    );
  }

  // 본인 메시지
  return (
    <MessageContainer $isMe={true}>
      <MessageInner className="message-me">
        {nickname === "Host" ? (
          <div className="en-message">{message.en}</div>
        ) : null}
        <div
          className="translated-message"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{message.message}</span>
        </div>
        <div className={`org-message ${message.isShowOrigin ? "" : "none"}`}>
          {message.tr}
        </div>
      </MessageInner>
      <div className="tool">
        <IconButton color="string" onClick={handleClickMessageMore}>
          <MoreVertIcon />
        </IconButton>
        <span>{createDate}</span>
      </div>
    </MessageContainer>
  );
};

export default Message;

const MessageContainer = styled.div`
  position: static;
  margin-top: 20px;
  font-size: 12px;
  align-self: ${({ $isMe }) => ($isMe ? "flex-end" : "flex-start")};
  font-weight: bold;
  display: flex;
  flex-direction: ${({ $isMe }) => ($isMe ? "row-reverse" : "row")};
  align-items: flex-end;

  & .tool {
    display: flex;
    flex-direction: ${({ $isMe }) => ($isMe ? "row-reverse" : "row")};
    align-items: center;
    color: #797979;
    font-weight: 400;
  }
`;

const MessageInner = styled.div`
  box-shadow: 5px 5px 10px #dcdcdc;
  border-radius: 10px;
  overflow: hidden;
  max-width: 50%;

  &.message-me {
    & .translated-message {
      width: 100%;
      min-height: 47px;
      height: auto;
      background-color: #4f8ae9;
      color: #fff;
      padding: 12px;
      font-size: 15px;
      white-space: normal;
      word-break: break-all;
    }

    & .en-message {
      min-height: 46px;
      height: auto;
      color: #797979;
      margin: 0 auto;
      padding: 12px;
      font-size: 15px;
      white-space: normal;
      word-break: break-all;
      background-color: #fff;
    }

    & .org-message {
      width: 100%;
      min-height: 46px;
      height: auto;
      color: #2e79f0;
      margin: 0 auto;
      padding: 12px;
      background-color: #fff;
      white-space: normal;
      word-break: break-all;

      &.none {
        display: none;
      }
    }
  }

  &.message-other {
    & .translated-message {
      width: 100%;
      min-height: 47px;
      height: auto;
      background-color: #d9e2ec;
      padding: 12px;
      white-space: normal;
      word-break: break-all;
      font-size: 15px;
    }

    & .en-message {
      width: 100%;
      min-height: 46px;
      height: auto;
      color: #797979;
      margin: 0 auto;
      padding: 12px;
      white-space: normal;
      word-break: break-all;
      font-size: 15px;
      background-color: #fff;
    }

    & .org-message {
      width: 100%;
      min-height: 46px;
      height: auto;
      color: #2e79f0;
      margin: 0 auto;
      padding: 12px;
      white-space: normal;
      word-break: break-all;
      font-size: 15px;
      background-color: #fff;

      &.none {
        display: none;
      }
    }
  }
`;
